/** @format */

.crm_card {
  max-width: 20.625rem;
  max-height: 20.625rem;
  min-height: 12.8125rem;
  border-radius: 0.9375rem;
  background: var(--Light-Grey---Input-Text, #d9d9d9);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.crm_card img {
  width: 75%;
}

.setting {
  position: absolute;
  bottom: 4%;
  left: 4%;
  border-radius: 6.25rem;
  background: var(--Darker-Grey---Fields-BG, #474747);
  padding: 0.19rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: all 0.1s;
  cursor: pointer;
}

.setting_arrow_wrapper {
  position: absolute;
  transform: translateX(-0.2rem);
  opacity: 0;
  transition: all 0.1s;
}

.setting:hover .setting_arrow_wrapper {
  position: static;
  transform: translateX(0);
  opacity: 1;
  transition: all 0.1s;
}

.toggle-btn {
  width: 3.8125rem;
  height: 2rem;
  background-color: #ccc;
  border-radius: 20px;
  position: absolute;
  cursor: pointer;
  border-radius: 6.25rem;
  border: 2.667px solid var(--Darker-Grey---Fields-BG, #474747);
  background: var(--Active-Black, #232325);
  bottom: 4%;
  right: 4%;
  z-index: 5;
}

.toggle-btn:hover {
  border: 2.667px solid var(--Dark-Grey---Paragraph-Text, #999);
  background: var(--Darker-Grey---Fields-BG, #474747);
}

.toggle-btn:hover .inner-circle {
  background-color: #999;
}

.inner-circle {
  width: 1.625rem;
  height: 1.525rem;
  background-color: #474747;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: transform 0.3s ease-in-out;
}

.toggle-btn.active {
  border: 2.667px solid #4caf50;
  background: #4caf50;
}

.toggle-btn.active .inner-circle {
  transform: translateX(1.625rem);
  background-color: white;
}
